import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import OpenAI from "../images/OpenAI.svg"

const Footer = () => (
  <footer>
    <div className="footer">
      <div className="left">
        © Aide. Powered by{" "}
        <a href="https://openai.com" target="_blank" className="openai">
          OpenAI <img src={OpenAI} />
        </a>
      </div>

      <div className="right">
        <Link to="/privacy">Privacy</Link>
        <Link to="/mission">Our mission</Link>
      </div>
    </div>
  </footer>
)

export default Footer
