import { useEffect } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { ChevronRight } from "react-feather"
import logo from "../images/logo.svg"

const Header = ({ siteTitle }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const crisp = document.createElement("script");
      crisp.innerHTML = `
          window.$crisp=[];
          CRISP_WEBSITE_ID="cea15da2-31f0-4458-8100-ecf5cc5de6e8";
        `;
      document.body.appendChild(crisp);

      const crispScript = document.createElement("script");
      crispScript.src = "https://client.crisp.chat/l.js";
      crispScript.async = true;
      document.body.appendChild(crispScript);

      return () => {
        document.body.removeChild(crisp);
        document.body.removeChild(crispScript);
      };
    }
  }, []);

  return(
  <header>
  <div className="hero-nav">
    <Link to="/" style={{ height: 72 }}>
      <img src={logo} alt="Aide Logo" style={{ width: "72px" }} />
    </Link>
    <div className="header-links">
      <Link to="/integrations">Integrations</Link>
    </div>

    <div className="hero-actions">
       <a href="https://my.aide.app/register" className="go" style={{ marginRight: "64px" }}>
       Register
      </a>
      <a href="https://my.aide.app/" className="go">
        Log in
        <ChevronRight className="log-in" />
      </a>
    </div>
  </div>
</header> 
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
